import Button from "./button";
import Modal from "./modal";

export default function InfoBoxDeliveryTime({onDismiss}) {

  return (
    <Modal wrapperClassName="InfoBox" onDismiss={onDismiss}>
      <div className="second-heading">
        <div className="overline">Information</div>
        <h3>Verzögerte Lieferzeiten</h3>
      </div>
      <p>
        Aufgrund des hohen Bestellaufkommen in den letzten Tagen kann es derzeit zu einer Verzögerung der Lieferzeit
        kommen. Wir bemühen uns, Ihre Bestellung so schnell wie möglich zu bearbeiten. Wir danken Ihnen für Ihr
        Verständnis!
      </p>
      <Button className="mt-1" text="Schliessen" onClick={onDismiss}/>
    </Modal>
  );

}
